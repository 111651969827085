import { Outlet } from "react-router-dom";
import './Layout.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Copyright from '../components/Copyright';
import Alert from '../components/Alert';
import { Container } from '@mui/material';

function Layout() {
    const mode = 'dark';
    const defaultTheme = createTheme({
        palette: {
            mode,
            // palette values for dark mode
            primary: {
                main: '#F5F5F5',
            },
            // divider: deepOrange[700],
            background: {
                default: "#20182f",
                paper: "#20182f",
            },
            text: {
                primary: '#F5F5F5',
                secondary: '#F5F5F5',
            },
        }
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            <AppAppBar />
            <CssBaseline />
            <Container sx={{ mt: '6rem', maxWidth: '1400px !important' }}>
                <Alert />
                <Outlet />
            </Container>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </ThemeProvider >
    );
}

export default Layout;
