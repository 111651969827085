import React, { useContext, useState } from 'react'

const messageTypeContext = React.createContext()
const messageTypeUpdateContext = React.createContext()

export function useMessageType() {
    return useContext(messageTypeContext)
}

export function useMessageUpdateType() {
    return useContext(messageTypeUpdateContext)
}

export function MessageTypeProvider({ children }) {

    const [messageType, setMessageType] = useState("");

    return (
        <messageTypeContext.Provider value={messageType}>
            <messageTypeUpdateContext.Provider value={setMessageType}>
                {children}
            </messageTypeUpdateContext.Provider>
        </messageTypeContext.Provider>
    )
}