import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Logo from '../../components/Logo';
import Highlights from '../../components/Highlights';
import YoutubeEmbed from '../../components/YoutubeEmbed';

export default function LandingPage() {
    return (
        <Box sx={{ bgcolor: 'background.default' }}>
            <Logo />
            <Divider />
            <YoutubeEmbed embedId="sbbqFjsCO80" />
            <Divider />
            <Highlights />
            <Divider />
            {/* <Pricing /> */}
        </Box>
    );
}
