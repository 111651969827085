import { Alert as AlertComponent } from '@mui/material';
import { useMessage, useMessageUpdate } from '../components/Message';
import { useMessageType } from '../components/MessageType';


export default function Alert() {
    const message = useMessage();
    const messageType = useMessageType();
    const setMessage = useMessageUpdate();

    return (
        <>
            {message !== "" && message !== undefined && <AlertComponent severity={messageType} onClose={() => { setMessage("") }}>
                {message}
            </AlertComponent>}
        </>
    );
}