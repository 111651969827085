import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

export default function Logo() {
    const theme = useTheme();
    const logo = theme.palette.mode === 'light' ? '/img/Logo.dark.png' : '/img/Logo.light.png';

    const logoStyle = {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '120px',
    };

    return (
        <Box id="logoCollection" sx={{ py: 4 }}>
            <Grid container >
                <Grid item justifyContent="center" sx={{ mt: 0.5 }}>
                    <img
                        src={logo}
                        style={logoStyle}
                        alt=''
                    />
                </Grid>
                {/* <Grid item justifyContent="center" sx={{ ml: 6 }}> */}
                <span className="word-loop-container">
                    <span >Fast</span>
                    <span >Reliable</span>
                    <span >Accurate</span>
                </span>
                {/* </Grid> */}
            </Grid>
        </Box>
    );
}
