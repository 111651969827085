import Cookie from 'universal-cookie';
import React, { useContext, useState } from 'react'

const regionContext = React.createContext()
const regionUpdateContext = React.createContext()

export function useRegion() {
    return useContext(regionContext)
}

export function useRegionUpdate() {
    return useContext(regionUpdateContext)
}

export function RegionProvider({ children }) {

    const cookie = new Cookie();
    const [region, setRegion] = useState(() => {
        try {
            const hash = decodeURIComponent(cookie.get('ID'));
            const speechToken = atob(decodeURIComponent(cookie.get(hash)));
            if (speechToken !== undefined) {
                const idx = speechToken.indexOf(':');
                return speechToken.slice(0, idx);
            }
            return "";
        } catch (e) {
            return "";
        }
    });

    return (
        <regionContext.Provider value={region}>
            <regionUpdateContext.Provider value={setRegion}>
                {children}
            </regionUpdateContext.Provider>
        </regionContext.Provider>
    )
}