import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.output.css';
import Layout from './page/Layout';
import Speech from './page/Speech/Speech';
import Login from './page/Login/Login'
import Register from './page/Register/Register'
import LandingPage from './page/LandingPage/LandingPage';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { EnvProvider } from './env/Env';
import { MessageProvider } from './components/Message';
import { MessageTypeProvider } from './components/MessageType';
import { TokenProvider } from './context/Token';
import { UsernameProvider } from './context/Username';
import { RegionProvider } from './context/Region';
import Edit from './page/Edit/Edit';

const router = createHashRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/register",
        element: <Register />
      },
      {
        path: "/speech",
        element: <Speech />
      },
      {
        path: "/edit",
        element: <Edit />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <EnvProvider>
      <TokenProvider>
        <RegionProvider>
          <UsernameProvider>
            <MessageProvider>
              <MessageTypeProvider>
                <RouterProvider router={router} />
              </MessageTypeProvider>
            </MessageProvider>
          </UsernameProvider>
        </RegionProvider>
      </TokenProvider>
    </EnvProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
