import React, { useContext, useState } from 'react'

const messageContext = React.createContext()
const messageUpdateContext = React.createContext()

export function useMessage() {
    return useContext(messageContext)
}

export function useMessageUpdate() {
    return useContext(messageUpdateContext)
}

export function MessageProvider({ children }) {

    const [message, setMessage] = useState("");

    return (
        <messageContext.Provider value={message}>
            <messageUpdateContext.Provider value={setMessage}>
                {children}
            </messageUpdateContext.Provider>
        </messageContext.Provider>
    )
}