import React, { useContext } from 'react'
import env from './env.prod';

const envContext = React.createContext()

export function useEnv() {
    return useContext(envContext)
}

export function EnvProvider({ children }) {
    return (
        <envContext.Provider value={env}>
            {children}
        </envContext.Provider>
    )
}