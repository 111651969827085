import * as React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useUsername } from '../context/Username';
import { useToken } from '../context/Token';
import { Divider } from '@mui/material';
import { Logout } from '@mui/icons-material';

import { useMessageUpdate } from '../components/Message'
import { useMessageUpdateType } from '../components/MessageType'
import Cookies from 'universal-cookie';

function AppAppBar() {

  const username = useUsername();
  const token = useToken();
  const setMessage = useMessageUpdate();
  const setMessageType = useMessageUpdateType();

  const clearToken = (e) => {
    e.preventDefault();
    try {
      const cookie = new Cookies();
      const hash = cookie.get('ID');
      cookie.remove('ID');
      cookie.remove(hash);
      setTimeout(() => {
        window.location.reload();
      }, 200);
      window.location = "/#/login";
    } catch (err) {
      setMessage("An error has occurred. When trying to save your credentials.");
      setMessageType("error");
    }
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
              <Button
                color="primary"
                variant="text"
                size="small"
                sx={{
                  fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                }}
              >
                <NavLink to="/">
                  Home Page
                </NavLink>
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                color="primary"
                variant="text"
                size="small"
                sx={{
                  fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                }}
              >
                <NavLink
                  to="/speech"
                  sx={{ py: '6px', px: '12px' }}
                >
                  Start Interpreting
                </NavLink>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
              <Typography
                component="h1"
                align="center"
                color="text.secondary"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'inherit',
                    md: 'inherit'
                  }
                }}
              >
                Expert Interpreting with AI Assistant
              </Typography>
            </Box>
            <Box
              sx={{
                display: { xs: 'flex', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {(username === "" || username === undefined) && (token === "" || token === undefined) && (
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  sx={{
                    fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                  }}
                >
                  <NavLink to="/login">
                    Sign in
                  </NavLink>
                </Button>
              )}
              {(username === "" || username === undefined) && (token === "" || token === undefined) && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                  }}
                >
                  <NavLink to="/register">
                    Sign up
                  </NavLink>
                </Button>
              )}
              {(username !== "" && username !== undefined) && (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    sx={{
                      fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                    }}
                  >
                    <NavLink to="/edit">
                      <SettingsIcon />
                    </NavLink>
                  </Button>
                  <Typography color="text.secondary" sx={{
                    fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                  }}>
                    {username}
                  </Typography>
                  <Button
                    title='logout'
                    color="primary"
                    variant="text"
                    size="small"
                    onClick={e => clearToken(e)}
                    sx={{
                      fontSize: { xs: "x-small", sm: 'unset', md: 'unset' }
                    }}
                  >
                    <Logout />
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
