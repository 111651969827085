import Cookie from 'universal-cookie';
import React, { useContext, useState } from 'react'

const usernameContext = React.createContext()
const usernameUpdateContext = React.createContext()

export function useUsername() {
    return useContext(usernameContext)
}

export function useUsernameUpdate() {
    return useContext(usernameUpdateContext)
}

export function UsernameProvider({ children }) {

    const cookie = new Cookie();
    const [username, setUsername] = useState(() => {
        try {
            const hash = decodeURIComponent(cookie.get('ID'));
            const usernameCookie = atob(hash);
            if (usernameCookie !== undefined) {
                return usernameCookie;
            }
            return "";
        } catch (e) {
            return "";
        }
    });

    return (
        <usernameContext.Provider value={username}>
            <usernameUpdateContext.Provider value={setUsername}>
                {children}
            </usernameUpdateContext.Provider>
        </usernameContext.Provider>
    )
}