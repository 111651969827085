import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEnv } from '../../env/Env';
import axios from 'axios';
import { useMessageUpdate } from '../../components/Message'
import { useMessageUpdateType } from '../../components/MessageType'
import { useUsername } from '../../context/Username'
import Cookie from 'universal-cookie';
import SettingsIcon from '@mui/icons-material/Settings';

export default function Edit() {
    const env = useEnv();
    const setMessage = useMessageUpdate();
    const setMessageType = useMessageUpdateType();

    const username = useUsername();

    const clearToken = () => {
        try {
            const cookie = new Cookie();
            const hash = cookie.get('ID');
            cookie.remove('ID');
            cookie.remove(hash);
            setTimeout(() => {
                window.location.reload();
            }, 200);
            window.location = "/#/login";
        } catch (err) {
            setMessage("An error has occurred. When trying to save your credentials.");
            setMessageType("error");
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const cookie = new Cookie();

        await axios.post(
            env["BACKEND_URL"] + "/api/profile/edit",
            {
                "username": formData.get('username'),
                "old-password": formData.get('old-password'),
                "new-password": formData.get('new-password'),
                "confirm-password": formData.get('confirm-password'),
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookie.get("session-id")
                },
                validateStatus: function (status) {
                    return status < 401;
                }
            }
        )
            .then(r => {
                const { data } = r;
                setMessage(data['message']);
                setMessageType(data['messageType']);
                if (r.status === 200) {
                    clearToken();
                }
            })
            .catch(e => {
                setMessage("An error has occurred. Please try again, or feel free to reach out to our support team for assistance.");
                setMessageType('error');
            });

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <SettingsIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        hidden="true"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        sx={{
                            display: "none"
                        }}
                        value={username}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="old-password"
                        label="Old Password"
                        type="password"
                        id="old-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="new-password"
                        label="New Password"
                        type="password"
                        id="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirm-password"
                        label="Confirm Password"
                        type="password"
                        id="confirm-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Edit
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container>
    );
}
